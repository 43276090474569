<div class="conteudoPageCrud">
  <app-titulo-edicao-crud [titulo]="'tituloSidebar.novidadesTooltip' | translate"></app-titulo-edicao-crud>

  <app-loader [loading]="loading" />

  @if (!loading && !novidades?.length) {
  <p translate>novidade.nmNenhumResultado</p>
  } @if (!loading) { @for (item of novidades | slice : startPagination : endPagination; track item) {
  <app-novidade-item [novidade]="item"></app-novidade-item>
  }
  <p-paginator
    [rows]="pageSize"
    [totalRecords]="totalRegistros"
    pageLinkSize="5"
    (onPageChange)="paginate($event)"
  ></p-paginator>
  }
</div>
