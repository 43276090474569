export class ResponseListNovidadeTO {
  dado: ResponseNovidadeTO[];
  qtRegistros: number;
  qtNaoLidas?: number;
}

export class ResponseNovidadeTO {
  dhCriacao: string;
  dsModulo: string;
  dsTitulo: string;
  id: string;
  dsConteudoHtml?: string;
  dsLinkImagem?: string;
}
