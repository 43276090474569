<p-dialog
  [(visible)]="show"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{width: '98%', 'max-width': '800px', 'max-height': '95vh', 'overflow': 'auto'}"
  [closable]="!showBotaoMarcarComoLido"
  [showHeader]="!showBotaoMarcarComoLido"
  [dismissableMask]="true"
  styleClass="popup-detalhes">

  <div class="detalhes-container">
    @if (novidadeCompleto?.dsLinkImagem) {
      <div [ngStyle]="{background: 'url('+novidadeCompleto?.dsLinkImagem+')'}"
        class="image-container"
        (click)="openFullImage()">
      </div>
    }

    <div class="detalhes-wrapper">
      <p class="titulo"> {{ novidadeCompleto?.dsTitulo }} </p>
      <p class="data">
        <span translate> novidade.nmModulo </span>
        <span> {{ novidadeCompleto?.dsModulo }} </span>
      </p>
      <p class="data"> {{ getDate(novidadeCompleto?.dhCriacao)?.toLocaleDateString() }}, {{ getDate(novidadeCompleto?.dhCriacao)?.toLocaleTimeString() }}</p>
      <p class="texto" [innerHtml]="novidadeCompleto?.dsConteudoHtml"></p>
    </div>
  </div>

  @if (showBotaoMarcarComoLido) {
    <div>
      <button pButton type="button" class="botao-salvar" (click)="marcarLido()" translate> novidade.marcarLido </button>
    </div>
  }
</p-dialog>


<p-dialog
  [(visible)]="showFullImage"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{width: '90%', 'max-width': '640px', 'max-height': '95vh', 'overflow': 'auto'}"
  [closable]="true"
  [dismissableMask]="true"
  styleClass="popup-detalhes">
  <img [src]="novidadeCompleto?.dsLinkImagem" class="full-img" [alt]="novidadeCompleto?.dsTitulo"/>
</p-dialog>
