import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NovidadesListComponent } from './novidades-list/novidades-list.component';

const routes: Routes = [{
  path: '', component: NovidadesListComponent
}]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ], exports: [
    RouterModule
  ]
})
export class NovidadesRoutingModule { }
