<app-popup-detalhes-novidade #popupDetalhes [novidadeCompleto]="novidadeCompleto">
</app-popup-detalhes-novidade>

<div class="novidade-container" (click)="getDetalhes()">
  <p class="titulo">{{ novidade.dsTitulo }}</p>
  <p class="data">
    <span translate> novidade.nmModulo </span>
    <span> {{ novidade.dsModulo }} </span>
  </p>
  <p class="data">
    {{ getDate(novidade.dhCriacao)?.toLocaleDateString() }},
    {{ getDate(novidade.dhCriacao)?.toLocaleTimeString() }}
  </p>
  <button pButton class="btn ver-mais" [loading]="loading">
    <span translate> novidade.nmVerMais </span>
  </button>
</div>
