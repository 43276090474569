import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NovidadesRoutingModule } from './novidades-routing.module';
import { NovidadesListComponent } from './novidades-list/novidades-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NovidadeItemComponent } from './components/novidade-item/novidade-item.component';
import { PopupDetalhesNovidadeComponent } from './components/popup-detalhes-novidade/popup-detalhes-novidade.component';
import { NovidadesNotificacaoComponent } from './components/novidades-notificacao/novidades-notificacao.component';

@NgModule({
  declarations: [
    NovidadesListComponent,
    NovidadeItemComponent,
    PopupDetalhesNovidadeComponent,
    NovidadesNotificacaoComponent
  ],
  imports: [
    CommonModule,
    NovidadesRoutingModule,
    SharedModule,
  ], exports: [
    NovidadesNotificacaoComponent
  ]
})
export class NovidadesModule { }
