import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseNovidadeTO } from 'src/app/shared/tos/response-novidade.to';

@Component({
  selector: 'app-popup-detalhes-novidade',
  templateUrl: './popup-detalhes-novidade.component.html',
  styleUrls: ['./popup-detalhes-novidade.component.scss']
})
export class PopupDetalhesNovidadeComponent implements OnInit {
  @Input() novidadeCompleto: ResponseNovidadeTO;
  @Input() showBotaoMarcarComoLido = false;
  @Output() marcarLidoClick = new EventEmitter();

  show: boolean = false;
  showFullImage = false;
  constructor() { }

  ngOnInit() {
  }

  getDate(date) {
    return new Date(date);
  }

  open() {
    this.show = true;
  }

  openFullImage() {
    this.showFullImage = true;
  }

  marcarLido() {
    this.show = false;
    this.marcarLidoClick.emit();
  }
}
