import { Component, OnInit } from '@angular/core';
import { NovidadesService } from 'src/app/shared/api-services/novidades.service';
import { ResponseNovidadeTO } from 'src/app/shared/tos/response-novidade.to';

@Component({
  selector: 'app-novidades-list',
  templateUrl: './novidades-list.component.html',
  styleUrls: ['./novidades-list.component.scss']
})
export class NovidadesListComponent implements OnInit {
  loading = false;
  novidades: ResponseNovidadeTO[];
  page: number = 1;
  pageSize: number = 5;
  totalRegistros: number;

  constructor(private novidadeService: NovidadesService) { }

  ngOnInit() {
    this.buscarNovidades();
  }

  buscarNovidades() {
    this.loading = true;
    this.novidadeService.getAll().subscribe(result => {
      this.novidadeService.setNovidadesVisualizadas(result.qtRegistros);
      this.loading = false;
      this.novidades = result.dado;
      this.totalRegistros = result.qtRegistros;
    }, err => {
      this.loading = false;
      throw err;
    });
  }

  paginate({ page }: any) {
    this.page = page + 1;
  }

  get startPagination() {
    return (this.page * this.pageSize) - this.pageSize;
  }

  get endPagination() {
    return this.page * this.pageSize;
  }
}
