import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ResponseListNovidadeTO, ResponseNovidadeTO } from "../tos/response-novidade.to";
import { catchError, switchMap } from "rxjs/operators";
import { UsuarioDataService } from "../services/usuario-data.service";
import { LocalStorageService } from "../services/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class NovidadesService {
  private QTD_NOVIDADES_LIDAS = "_sme-qtd-novidades-lidas";
  private qtdNaoLidas = new BehaviorSubject<number>(0);
  private qtdLidas: number = 0;

  constructor(
    private httpClient: HttpClient,
    private usuarioDataService: UsuarioDataService,
    private localStorageService: LocalStorageService
  ) {}

  private versionAPI: string = "/v1";
  private api: string = "/geral-api";
  private path: string = "/novidade";
  private url: string = this.api + this.versionAPI + this.path;

  getAll(): Observable<ResponseListNovidadeTO> {
    const loginName = this.usuarioDataService.getLoginName();
    return this.httpClient.get<ResponseListNovidadeTO>(`${this.url}?ck=${loginName}`);
  }

  getDetalhes(id: string): Observable<ResponseNovidadeTO> {
    return this.httpClient.get<ResponseNovidadeTO>(`${this.url}/${id}`);
  }

  verificarNovidadesNaoLidas() {
    return this.getAll().pipe(
      catchError((err) => of(null)),
      switchMap((response: ResponseListNovidadeTO) => {
        if (!response) {
          response = new ResponseListNovidadeTO();
        }
        const salvoQtdLido = this.localStorageService.getItem(this.QTD_NOVIDADES_LIDAS);
        this.qtdLidas = salvoQtdLido ? Number(salvoQtdLido) : 0;
        response.qtNaoLidas = (response?.qtRegistros || 0) - this.qtdLidas;
        this.setQtdNovidadesNaoLidas(response.qtNaoLidas);
        return response.qtNaoLidas > 0 ? this.getDetalhes(response.dado[0].id) : of(null);
      })
    );
  }

  getQtdNaoLidas() {
    return this.qtdNaoLidas;
  }

  getQtdLidas() {
    return this.qtdLidas;
  }

  setQtdNovidadesNaoLidas(nrNaoLidas: number) {
    this.qtdNaoLidas.next(nrNaoLidas);
  }

  setNovidadesVisualizadas(qtdRegistros: number) {
    this.setQtdNovidadesNaoLidas(0);
    this.localStorageService.setItem(this.QTD_NOVIDADES_LIDAS, qtdRegistros);
  }
}
