import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NovidadesService } from 'src/app/shared/api-services/novidades.service';
import { ResponseNovidadeTO } from 'src/app/shared/tos/response-novidade.to';
import { PopupDetalhesNovidadeComponent } from '../popup-detalhes-novidade/popup-detalhes-novidade.component';

@Component({
  selector: 'app-novidade-item',
  templateUrl: './novidade-item.component.html',
  styleUrls: ['./novidade-item.component.scss']
})
export class NovidadeItemComponent implements OnInit {
  @ViewChild('popupDetalhes') popupDetalhes: PopupDetalhesNovidadeComponent;
  @Input() novidade: ResponseNovidadeTO;
  novidadeCompleto: ResponseNovidadeTO;
  loading = false;

  constructor(private novidadeService: NovidadesService) { }

  ngOnInit() {
  }

  getDate(date) {
    return new Date(date);
  }

  getDetalhes() {
    if (this.novidadeCompleto) {
      this.popupDetalhes.open();
      return;
    }
    this.loading = true;
    this.novidadeService.getDetalhes(this.novidade.id).subscribe(result => {
      this.loading = false;
      this.novidadeCompleto = result;
      this.popupDetalhes.open();
    }, err => {
      this.loading = false;
      throw err;
    })
  }

}
